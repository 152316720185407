import { links } from "%/enums/link-enums";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const usePostFeedStore = defineSmartFeedPiniaStore("post-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useFanPublicationStore().publicationId,
    },
    isLoading: false,
  }),
  getters: {
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    reload() {
      this.$reset();
      this.getSmartFeed();
    },
    getSmartFeed() {
      this.apiGet(
        `publication/${this.metadata.publicationId}/posts/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => {
          this.handleSmartFeedResponse(data);
        },
        () => {
          // prevents infinite errors
          this.metadata.hasMore = false;
        },
      );
    },
  },
});
