<template>
  <iRow :wrap="$vssTablet ? 'nowrap' : 'wrap'" align="right" vertical-align="middle">
    <iColumn
      width="hug"
      wrap="nowrap"
    >
      <iCheckbox
        v-model="isPreview"
        label="Preview"
      />
    </iColumn>
    <iColumn
      width="hug"
      wrap="nowrap"
    >
      <iCheckbox
        v-model="allowsRemixing"
        label="Allow Remixing"
      />
    </iColumn>
    <iColumn
      width="hug"
      wrap="nowrap"
    >
      <iCheckbox
        v-model="requiresSyndicationApproval"
        label="Requires Syndication Approval"
      />
    </iColumn>
    <iColumn width="hug">
      <iButton
        v-if="story.variant_count > 1"
        class="text-btn"
        variant="text"
        @click="showShowStoryVariantsModal = true;"
      >
        1 of {{ story.variant_count }} variants
      </iButton>
      <iButton
        v-else
        class="text-btn"
        variant="text"
        @click="showCreateVariantsModal = true;"
      >
        No variants
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton
        v-if="!story.publishing_platform_id"
        class="text-btn"
        variant="text"
        @click="triggerStoryAssignModal"
      >
        Assign to platform
      </iButton>
      <iText v-else>
        {{ story.publishing_platform_name }}
      </iText>
    </iColumn>
    <iRow gap="large" width="hug" wrap="nowrap">
      <iRow
        gap="extraSmall"
        vertical-align="middle"
        width="hug"
        wrap="nowrap"
      >
        <iCheckbox
          v-model="isStoryExclusive"
          :is-loading="updateStoryExclusivityIsLoading"
          @change="updateStoryExclusivityFlag"
        />
        <iColumn v-tooltip="exclusiveCheckboxTooltip" width="hug">
          <iIcon icon="dollar" variant="branded" />
        </iColumn>
      </iRow>
      <iRow
        gap="extraSmall"
        vertical-align="middle"
        width="hug"
        wrap="nowrap"
      >
        <iCheckbox
          v-model="isStoryInSyndicationMarketplace"
          :is-loading="updateStoryInSyndicationMarketplaceIsLoading"
          @change="updateSyndicationMarketplaceFlag"
        />
        <iColumn v-tooltip="syndicationMarketplaceCheckboxTooltip" width="hug">
          <iIcon icon="shopping-bag" variant="branded" />
        </iColumn>
      </iRow>
    </iRow>
    <iColumn vertical-align="middle" width="hug">
      <iButton class="text-btn" variant="text" @click="triggerHashtagsModal = true">
        <iRow gap="extraSmall" vertical-align="middle" wrap="nowrap">
          <iText>+ Hashtags</iText>
          <iText v-if="hashtags.length">
            ({{ hashtags.length }})
          </iText>
        </iRow>
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iText :wrap="false" variant="branded">
        {{ savingStatus }}
      </iText>
    </iColumn>
  </iRow>

  <iEditor
    ref="editor"
    :preview="isPreview"
    :segments="segments"
    :upload-post-image="uploadImageFunction"
    :upload-post-video="uploadVideoFunction"
    height="fill"
    include-margin
    overflow="auto"
    variant="builder"
    width="fill"
    @input="saveWithDebounce"
  >
    <template #above-editor>
      <iRow
        width="fill"
        wrap="nowrap"
        :style-overrides="{
          marginTop: '8px',
        }"
      >
        <iTextEdit
          id="title"
          ref="title"
          :value="title"
          :show-icon-on-edit="false"
          class="native-story-title"
          name="title"
          placeholder="Title"
          type="text"
          inline
          width="hug"
          :min-width="50"
          @input="title = $event; saveTitleWithDebounce()"
        />
      </iRow>
    </template>
  </iEditor>

  <iModal
    v-if="assignToPublishingPlatformModalOpen"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :primary-action-button-loading="updateStoryIsLoading"
    :width="600"
    primary-action-button-label="Continue"
    title="Assign Publication"
    @close="assignToPublishingPlatformModalOpen = false"
    @click:primary="assignStory"
    @click:secondary="assignToPublishingPlatformModalOpen = false"
  >
    <template #body>
      <iColumn max-width-only>
        <iSelect
          v-if="publishingPlatformType === 'publication'"
          :items="myPublications"
          :model-value="publishingPlatformId"
          label="Select a publication"
          label-field="name"
          return-value
          value-field="publication_id"
          width="fill"
          @update:model-value="publishingPlatformId = $event.toString()"
        />
      </iColumn>
    </template>
  </iModal>
  <template v-if="story">
    <ShowVariantsModal
      :parent-id="story.variant_parent_id"
      :show="showShowStoryVariantsModal"
      :story-id="story.story_id"
      :story-title="story.title"
      @close="showShowStoryVariantsModal = false"
    />
    <CreateVariantsModal
      :show="showCreateVariantsModal"
      :story-id="story.story_id"
      :story-title="story.title"
      @close="showCreateVariantsModal = false"
    />
    <iModal
      v-if="showEditStoryConfirmation"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :primary-action-button-loading="updateStoryIsLoading"
      :width="600"
      primary-action-button-label="Continue"
      title="Edit Story"
      @close="closeEditStoryConfirmation"
      @click:primary="forceDraftStatusConfirmation"
      @click:secondary="closeEditStoryConfirmation"
    >
      <template #body>
        <iParagraph>
          Your story is currently live. If you want to continue editing, your story will be changed to draft.
        </iParagraph>
      </template>
    </iModal>
  </template>

  <iModal
    v-if="triggerHashtagsModal"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :max-width="600"
    :secondary-action-button-loading="updateStoryIsLoading"
    :show-primary-action-button="false"
    secondary-action-button-label="Close"
    title="Hashtags"
    width="fill"
    @close="triggerHashtagsModal = false"
    @click:secondary="triggerHashtagsModal = false"
  >
    <template #body>
      <iRow>
        <iHashtagInput
          v-model="hashtags"
          :validation="validateHashtag"
          hashtag-value-key="hashtag"
          @submit:hashtag="addHashtag"
          @delete:hashtag="removeHashtag"
        />
      </iRow>
    </template>
  </iModal>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { postStatus } from "@/enums/post-enums";
import { getRelativeTimeString, useWindowStore } from "@bloglovin/vue-component-library";
import ShowVariantsModal from "@/components/publication/ShowVariantsModal";
import CreateVariantsModal from "@/components/publication/CreateVariantsModal";
import { useStoryStore } from "%/stores/story-store";
import { camelToTitleCase } from "@bloglovin/vue-framework";

export default {
  name: "StoryEditorComponentDark",
  components: {
    CreateVariantsModal,
    ShowVariantsModal,
  },
  props: {
    storyData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    saveWhileActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isStoryExclusive: false,
      isStoryInSyndicationMarketplace: false,
      showEditStoryConfirmation: false,
      debounce: null,
      storyLastUpdated: null,
      title: "",
      isPreview: false,
      allowsRemixing: false,
      requiresSyndicationApproval: false,
      triggerHashtagsModal: false,
      assignToPublishingPlatformModalOpen: false,
      showShowStoryVariantsModal: false,
      showCreateVariantsModal: false,
      initialLoad: 0,
      savingStatus: "Changes Saved",
      forceDraftStatus: false,
      publishingPlatformType: "publication",
      publishingPlatformId: "",
      savingStatusse: {
        SAVED: "Changes Saved",
        UNSAVED: "Unsaved Changes",
        SAVING: "Saving Changes",
        ERROR: "Error Saving Changes",
      },
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssTablet",
    ]),
    ...mapState(usePublicationStore, [
      "publicationId",
      "myPublications",
    ]),
    ...mapState(useStoryStore, [
      "uploadStoryImageLoading",
      "loadingAddHashtagToStory",
      "errorAddHashtagToStory",
      "updateStoryExclusivityIsLoading",
      "updateStoryInSyndicationMarketplaceIsLoading",
    ]),
    ...mapState(useStoryStore, {
      storyStoreData: "story",
    }),
    ...mapWritableState(useStoryStore, ["updateStoryIsLoading"]),
    story() {
      if (Object.keys(this.storyData).length === 0) {
        return this.storyStoreData;
      }
      return this.storyData;
    },
    segments() {
      const segmentArray = Object.values(this.story.segments);
      return this.story.segment_order.reduce((acc, curr) => {
        const segment = segmentArray.find(seg => seg.story_segment_id === curr.story_segment_id);
        return [
          ...acc,
          segment,
        ];
      }, []);
    },
    exclusiveCheckboxTooltip() {
      return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
    },
    syndicationMarketplaceCheckboxTooltip() {
      return "You can share this content in the syndication marketplace to allow other creators to add your content to their publications. " +
          "You will still retain story ownership and share revenue earned from those publications";
    },
    hashtags: {
      get() {
        return this.story.hashtags || [];
      },
      set(value) {
        this.story.hashtags = value;
      },
    },
  },
  mounted() {
    if (this.story.title) {
      this.title = this.story.title || "New Draft Story";
    }
    this.isStoryExclusive = !!this.story.is_private;
    this.isStoryInSyndicationMarketplace = !!this.story.in_syndication_marketplace;
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    camelToTitleCase,
    ...mapActions(useStoryStore, [
      "uploadStoryImage",
      "uploadStoryVideo",
      "updateStory",
      "addHashtagsToStory",
      "removeHashtagFromStory",
      "updateStoryExclusiveContentFlag",
      "updateStorySyndicationMarketplaceFlag",
      "updateStoryTitle",
      "assignStoryToPublishingPlatform",
    ]),
    triggerStoryAssignModal() {
      if (this.story.publishing_platform_id && this.story.publishing_platform_id > 0) {
        return;
      }
      this.assignToPublishingPlatformModalOpen = true;
    },
    closeEditStoryConfirmation() {
      this.showEditStoryConfirmation = false;
    },
    forceDraftStatusConfirmation() {
      this.showEditStoryConfirmation = false;
      this.forceDraftStatus = true;
    },
    saveWithDebounce(content) {
      this.handleBeforeSave(() => {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = this.savingStatusse.SAVING;
          this.submitStory(content, () => {
            this.savingStatus = this.savingStatusse.SAVED;
          }, () => {
            this.savingStatus = this.savingStatusse.ERROR;
          });
        }, 1000);
      });
    },
    saveTitleWithDebounce() {
      this.handleBeforeSave(() => {
        this.savingStatus = this.savingStatusse.UNSAVED;
        this.updateStoryIsLoading = true;
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = this.savingStatusse.SAVING;
          this.updateStoryTitle(this.story.story_id, this.title, () => {
            this.savingStatus = this.savingStatusse.SAVED;
          }, () => {
            this.savingStatus = this.savingStatusse.ERROR;
          });
        }, 1000);
      });
    },
    addHashtag(newTag) {
      this.savingStatus = this.savingStatusse.SAVING;
      this.addHashtagsToStory([newTag], this.story.story_id, () => {
        this.savingStatus = this.savingStatusse.ERROR;
        this.hashtags.pop();
      }, () => {
        this.savingStatus = this.savingStatusse.SAVED;
      });
    },
    handleBeforeSave(process = () => {
    }) {
      if (this.initialLoad < 1) {
        this.initialLoad++;
        return;
      }
      if (this.story.status === postStatus.ACTIVE && !this.saveWhileActive && !this.forceDraftStatus) {
        this.showEditStoryConfirmation = true;
        if (this.story.story_title) {
          this.$refs.title.setValue(this.story.story_title);
        }
        return;
      }
      process();
    },
    removeHashtag({ el, index }) {
      this.savingStatus = this.savingStatusse.SAVING;
      this.removeHashtagFromStory(el.hashtag_id, this.story.story_id, () => {
        this.savingStatus = this.savingStatusse.ERROR;
        this.hashtags.splice(index, 0, el);
      }, () => {
        this.savingStatus = this.savingStatusse.SAVED;
      });
    },
    validateHashtag(value) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(value);
      if (!result) {
        return "Hashtag must be alphanumeric and can only contain underscores.";
      }
      return true;
    },
    async uploadImageFunction(file) {
      return await this.uploadStoryImage(file);
    },
    async uploadVideoFunction(file) {
      return await this.uploadStoryVideo(file);
    },
    submitStory(content, onComplete = () => {
    }, onError = () => {
    }) {
      if (this.story.status !== postStatus.ACTIVE) {
        this.updateStoryIsLoading = false;
      }
      this.updateStory(this.story.story_id, content, onComplete, onError);
    },
    updateStoryExclusivityFlag() {
      this.savingStatus = this.savingStatusse.SAVING;
      this.updateStoryExclusiveContentFlag(this.story.story_id, this.isStoryExclusive, () => {
        this.savingStatus = this.savingStatusse.SAVED;
      }, () => {
        this.savingStatus = this.savingStatusse.ERROR;
      });
    },
    updateSyndicationMarketplaceFlag() {
      this.savingStatus = this.savingStatusse.SAVING;
      this.updateStorySyndicationMarketplaceFlag(this.story.story_id, this.isStoryInSyndicationMarketplace, () => {
        this.savingStatus = this.savingStatusse.SAVED;
      }, () => {
        this.savingStatus = this.savingStatusse.ERROR;
      });
    },
    assignStory() {
      this.savingStatus = this.savingStatusse.SAVING;
      this.assignToPublishingPlatformModalOpen = false;

      if (this.publishingPlatformType === "publication") {
        const publication = this.myPublications.find(pub => pub.publication_id === parseInt(this.publishingPlatformId));
        this.story.publishing_platform_name = publication.name;
      }

      this.assignStoryToPublishingPlatform(
        this.story.story_id,
        this.publishingPlatformType,
        this.publishingPlatformId,
        () => {
          this.savingStatus = this.savingStatusse.SAVED;
          this.story.publishing_platform_id = this.publishingPlatformId;
          this.story.publishing_platform_type = this.publishingPlatformType;
        },
        () => {
          this.savingStatus = this.savingStatusse.ERROR;
        },
      );
    },
    getRelativeTimeString,
  },
};
</script>

<style lang="scss" scoped>
:deep(toptap.ProseMirror) {
  height: 100%;
  box-sizing: border-box;
  cursor: text;
}

.native-story-title {
  margin-top: 4px;

  :deep(.i-text.text-edit) {
    width: 100%;
    font-size: 26px;
    font-weight: 700;
  }
}
</style>
