<template>
  <PageWrapper :active-page="PAGE_MARKETPLACE" :active-section="SECTION_COMMUNITY">
    <iColumn
      align="center"
      class="post-container"
      height="fill"
      wrap="nowrap"
    >
      <iRow width="fill" height="hug" vertical-align="middle">
        <div class="alert-container info">
          <iRow wrap="nowrap" vertical-align="middle">
            <iColumn>
              <slot name="title">
                <h3 class="alert-container-title">
                  Syndicate Story
                </h3>
                <iColumn>
                  <iParagraph>
                    When you syndicate a story, you'll be sharing the revenue with the original owner. This revenue-sharing
                    arrangement ensures that the creator of the content is properly compensated for their work.
                  </iParagraph>
                  <iParagraph v-if="story.allows_remix">
                    This story can be remixed.
                  </iParagraph>
                  <iParagraph v-else>
                    You are not allowed to modify the content of this story.
                  </iParagraph>
                </iColumn>
              </slot>
              <slot />
            </iColumn>
            <iButton variant="secondary" width="hug">
              Syndicate
            </iButton>
          </iRow>
        </div>
      </iRow>
      <iColumn>
        <iText v-if="story.is_private" variant="subtle">
          Exclusive Content
        </iText>
        <iHeading
          :style-overrides="{
            'standardFontWeight': 'dark',
            'marginBottom': 'none'
          }"
        >
          {{ story?.title }}
        </iHeading>
        <iRow>
          <template v-for="hashtag in hashtags" :key="hashtag.hashtag_id">
            <iText class="hashtag-link">
              #{{ hashtag.hashtag }}
            </iText>
          </template>
        </iRow>
        <iStyledCard
          :author-avatar="authorAvatar"
          :author-description="relativeDateCreated"
          :author-name="authorName"
        />
      </iColumn>
      <iEditor
        ref="editor"
        :segments="Object.values(story.segments)"
        height="auto"
        overflow="none"
        preview
        variant="builder"
        width="fill"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { SECTION_COMMUNITY, PAGE_MARKETPLACE } from "@/constants/nav-constants";
import { mapState } from "pinia";
import { useStoryStore } from "%/stores/story-store";
import dayjs from "dayjs";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "SyndicationMarketplaceStory",
  components: { PageWrapper },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_MARKETPLACE,
    };
  },
  computed: {
    ...mapState(useStoryStore, ["story"]),
    hashtags() {
      return this.story.hashtags;
    },
    relativeDateCreated() {
      const date = dayjs.unix(this.story.timestamp_created);
      return dayjs(date).fromNow();
    },
    authorName() {
      return this.story.author_name || this.story.publishing_platform_name;
    },
    authorAvatar() {
      return this.story.author_avatar || DEFAULT_USER_AVATAR;
    },
  },
  styleGuide: () => ({
    infoBackgroundColor: { "color.background": "subtle" },

    infoFontColor: { "color.font": "standard" },

    alertPadding: { "size.spacing": "standard" },

    titleFontSize: { "size.font": "large" },

    borderRadius: { "size.borderRadius": "large" },
  }),
};
</script>

<style lang="scss" scoped>
.post-container {
  padding: 8px;
}

.alert-container {
  padding: v-bind('$getStyles.alertPadding');
  width: 100%;
  box-sizing: border-box;
  border-radius: v-bind('$getStyles.borderRadius');

  &.info {
    background-color: v-bind('$getStyles.infoBackgroundColor');
    color: v-bind('$getStyles.infoFontColor');
  }

  &-title {
    font-weight: bold;
    font-size: v-bind('$getStyles.titleFontSize');
    margin: 0;
  }
}
</style>
