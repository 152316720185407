<template>
  <div class="test-child">child</div>
</template>

<script>
export default {
  name: "TestChild",
  props: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.test-child {
  font-size: 48px;
}
</style>
