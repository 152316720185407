<template>
  <div data-nick="disanto">hi</div>
  <span>hi</span>
</template>

<script>
import SplashColumnContainer from "@/components/splash/SplashColumnContainer";
import SplashTextColumn from "@/components/splash/SplashTextColumn";
import SplashJoinWaitingListContainer from "@/components/splash/SplashJoinWaitingListContainer";
import SplashFooter from "@/components/splash/SplashFooter";
import SplashWrapper from "@/components/splash/SplashWrapper";
import { h } from 'vue';


export default {
  name: "Splash",
  components: { SplashWrapper, SplashFooter, SplashJoinWaitingListContainer, SplashTextColumn, SplashColumnContainer },
  beforeMount() {
  },
  render() {
debugger;
    return h('div', "hello")
  },
onRenderTriggered() {
debugger;
},
  renderTriggered() {
debugger;
  },
  mounted() {
console.log(this.$el.parentNode.children);debugger;
this.$el.parentNode.children.forEach(el => {
el.attributes.setNamedItem('testing');
});
debugger;
  },
  data() {
    return {
      carouselAnimations: [
        {
          effect: "scroll-x",
          options: {
            duration: 12 * 2.5 * 1000,
          },
          onAction: "page_load",
        },
        {
          effect: "blur",
          options: {
            duration: 200,
            to: 2,
          },
          onAction: "hover",
          target: ".i-carousel-item",
        },
        {
          effect: "scale",
          options: {
            duration: 400,
            to: 1.1,
          },
          onAction: "hover",
          target: ".i-carousel-item img:hover",
        },
      ],
      images: [
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-1.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-2.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-3.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-4.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-5.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-6.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-7.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-8.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-9.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-10.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-11.webp",
          alt: "placeholder",
        },
        {
          src: "https://cdn-alpha.inspiration.co/images/splash/splash-12.webp",
          alt: "placeholder",
        },
      ],
      imageWidth: 616,
    };
  },
  methods: {
    joinWaitlist() {
      // TODO: Implement
    },
  },
  styleGuide: () => ({
    marketingHeadingFontSize: { "size.font": "displayMedium" },
    tabletMarketingHeadingFontSize: { "size.font": "displayMedium" },
    mobileMarketingHeadingFontSize: { "size.font": "displayVerySmall" },
    mobileMarketingHeadingFontWeight: { "font.weight": "dark" },

    marketingTextFontSize: { "size.font": "extraLarge" },
    tabletMarketingTextFontSize: { "size.font": "extraLarge" },
    mobileMarketingTextFontSize: { "size.font": "large" },

  }),
};
</script>

<style lang="scss" scoped>
.marketing-heading {
  max-width: 925px;
  padding: 0px 20px;
  @include phone {
  }

  .i-display-text {
    font-size: v-bind("$getStyles.marketingHeadingFontSize");

    @include tablet {
      font-size: v-bind("$getStyles.tabletMarketingHeadingFontSize");
    }

    @include phone {
      font-size: v-bind("$getStyles.mobileMarketingHeadingFontSize");
      font-weight: v-bind("$getStyles.mobileMarketingHeadingFontWeight");
    }
  }
}

.left-right {
  padding: 0 20px;
}

.marketing-text {
  max-width: 900px;
  padding: 0 20px;
  @include phone {
  }

  .i-heading {
    font-size: v-bind("$getStyles.marketingTextFontSize");

    @include tablet {
      font-size: v-bind("$getStyles.tabletMarketingTextFontSize");
    }

    @include phone {
      font-size: v-bind("$getStyles.mobileMarketingTextFontSize");
    }
  }
}

.mirror {
  transform: scalex(-1);
}
</style>
