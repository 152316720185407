// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-275b8434] .test-child {
  color: blue;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/splash/TestParent.vue","webpack://./TestParent.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.test-child) {\n  color: blue;\n}\n",":deep(.test-child) {\n  color: blue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
