<template>
  <PageWrapper
    :active-page="PAGE_STORIES"
    :active-section="SECTION_STORIES"
    :breadcrumbs="breadcrumbs"
    page-name="My Stories"
  >
    <iRow align="between" wrap="nowrap">
      <iColumn width="hug">
        <iHeading>My Stories</iHeading>
      </iColumn>
      <iColumn width="hug">
        <iButton
          :is-loading="isLoadingCreateStory"
          loading-spinner-position="before"
          variant="secondary"
          width="hug"
          @click="createStory"
        >
          Create Story
        </iButton>
      </iColumn>
    </iRow>
    <SmartFeed
      :pinia-store="useOrgPostFeedStore"
      default-entity-layout="tableRow"
      default-smart-feed-layout="table"
      sticky-headers
      vertical-align="middle"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_STORIES, PAGE_STORIES } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useOrgPostFeedStore } from "SmartFeed/stores/feed/org-post-feed-store";
import { mapActions, mapState } from "pinia";
import { useStoryStore } from "%/stores/story-store";

export default {
  name: "Stories",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_STORIES,
      PAGE_STORIES,
    };
  },
  computed: {
    ...mapState(useStoryStore, ["isLoadingCreateStory"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
      ];
    },
  },
  methods: {
    ...mapActions(useStoryStore, ["createStory"]),
    useOrgPostFeedStore,
  },
};
</script>

<style scoped>
</style>
