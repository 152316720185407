<template>
  <PageWrapper
    :action-button-href="`/monetization/org/${orgId}/links/collections`"
    :active-page="PAGE_LINKS_COLLECTIONS"
    :active-section="SECTION_MONETIZATION"
    :breadcrumbs="breadcrumbs"
    :page-name="collectionName"
    :title="`Collection - ${collectionName}`"
    action-button-text="View Collections"
  >
    <iRow overflow="scroll">
      <iRow :width="2700">
        <iDataTable
          :fields="fields"
          :has-more="hasMoreAffiliateLinks"
          :is-loading="loadingAffiliateLinks"
          :items="affiliateLinks[collectionId]"
          hide-actions
          primary-key="publisher_affiliate_link_id"
          @load-more="fetchAffiliateLinksByCollectionId(collectionId)"
        >
          <template #td(affiliate_url)="{data}">
            <iText :href="data.short_link.short_link">
              {{ data.short_link.short_link }}
            </iText>
          </template>
          <template #td(original_url)="{data}">
            <iText :href="data.short_link.destination_url">
              {{ data.short_link.destination_url }}
            </iText>
          </template>
          <template #td(description)="{data, index}">
            <iTextEdit
              :value="data.link_description"
              :width="200"
              inline
              @input="(value) => saveLinkDescription(data, index, value)"
            />
          </template>
          <template #td(advertiser)="{data}">
            {{ data.advertiser.advertiser_name }}
          </template>
          <template #td(brand_name)="{data, index}">
            <iTextEdit
              :model-value="data.brand"
              :width="200"
              inline
              @input="(value) => saveBrandName(data, index, value)"
            />
          </template>
          <template #td(product_name)="{data, index}">
            <iTextEdit
              :value="data.product_name"
              :width="200"
              inline
              @input="(value) => saveProductName(data, index, value)"
            />
          </template>
          <template #td(clicks)="{data}">
            {{ data.performance.clicks }}
          </template>
          <template #td(sales)="{data}">
            {{ data.performance.sales.sales_count }}
          </template>
          <template #td(pending_earnings)="{data}">
            <template v-if="data.performance.pending_earnings.total_pending_earnings">
              {{
                getFormattedCurrencyValue(
                  data.performance.pending_earnings.commission_currency,
                  data.performance.pending_earnings.total_pending_earnings
                )
              }}
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template #td(locked_earnings)="{data}">
            <template v-if="data.performance.locked_earnings.total_locked_earnings">
              {{
                getFormattedCurrencyValue(
                  data.performance.locked_earnings.commission_currency,
                  data.performance.locked_earnings.total_locked_earnings
                )
              }}
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template #td(total_earnings)="{data}">
            <template
              v-if="data.performance.locked_earnings.total_locked_earnings || data.performance.pending_earnings.total_pending_earnings"
            >
              {{ getTotalEarningsCellContent(data) }}
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template #td(actions)="{data}">
            <iDropdown
              :show-dropdown="affiliateDropdownId === data.publisher_affiliate_link_id"
              position="right"
              @close="closeAffiliateDropdown"
            >
              <template #trigger>
                <iIcon icon="dots-vertical" @click="openAffiliateDropdown(data.publisher_affiliate_link_id)" />
              </template>
              <template #overlay>
                <iButton variant="secondary" @click="openAddToCollectionModal(data)">
                  Add to Collection
                </iButton>
              </template>
            </iDropdown>
          </template>
        </iDataTable>
      </iRow>
    </iRow>
    <iModal
      :show-footer="false"
      :visible="isAddToCollectionModalOpen"
      :width="550"
      title="Add Link to Collection"
      @close="closeAddToCollectionModal"
    >
      <template #body>
        <SelectAffiliateCollection :affiliate-link-id="affiliateLink.publisher_affiliate_link_id" />
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { SECTION_MONETIZATION, PAGE_LINKS_COLLECTIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper";
import SelectAffiliateCollection from "@/components/SelectAffiliateCollection";

export default {
  name: "CollectionsReport",
  components: {
    PageWrapper,
    SelectAffiliateCollection,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    affiliateLinkCollection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINKS_COLLECTIONS,
      fields: [
        {
          key: "affiliate_url",
          label: "Affiliate Url",
          width: "350px",
        },
        {
          key: "original_url",
          label: "Original Url",
          width: "350px",
        },
        {
          key: "advertiser",
          label: "Advertiser",
          width: "200px",
        },
        {
          key: "description",
          label: "My Description",
          width: "350px",
        },
        {
          key: "brand_name",
          label: "Brand Name",
          width: "350px",
        },
        {
          key: "product_name",
          label: "Product Name",
          width: "350px",
        },
        {
          key: "clicks",
          label: "Clicks",
        },
        {
          key: "sales",
          label: "Sales",
        },
        {
          key: "total_earnings",
          label: "Total Earnings",
        },
        {
          key: "pending_earnings",
          label: "Pending Earnings",
        },
        {
          key: "locked_earnings",
          label: "Locked Earnings",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      affiliateLink: {},
      isAddToCollectionModalOpen: false,
      affiliateDropdownId: -1,
    };
  },
  computed: {
    ...mapWritableState(useAffiliateLinkStore, [
      "editingRowColumn",
      "editingValue",
      "editingRowKey",
    ]),
    ...mapState(useAffiliateLinkStore, [
      "orgId",
      "affiliateLinks",
      "loadingAffiliateLinks",
      "hasMoreAffiliateLinks",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
        { url: `/monetization/org/${this.orgId}/links`, label: "My Links" },
        { url: `/monetization/org/${this.orgId}/links/collections`, label: "Collections" },
      ];
    },
    collectionName() {
      return this.affiliateLinkCollection.name;
    },
  },
  async created() {
    this.fetchAffiliateLinksByCollectionId(this.collectionId);
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, [
      "editAffiliateLinkProductDetails",
      "fetchAffiliateLinksByCollectionId",
    ]),
    openAddToCollectionModal(data) {
      this.affiliateLink = data;
      this.isAddToCollectionModalOpen = true;
      this.closeAffiliateDropdown();
    },
    closeAddToCollectionModal() {
      this.affiliateLink = {};
      this.isAddToCollectionModalOpen = false;
    },
    openAffiliateDropdown(id) {
      this.affiliateDropdownId = id;
    },
    closeAffiliateDropdown() {
      this.affiliateDropdownId = -1;
    },
    getTotalEarningsCellContent(data) {
      const currency = data.performance.locked_earnings.commission_currency ?? data.performance.pending_earnings.commission_currency;
      let totalEarnings = 0.000;

      if (data.performance.locked_earnings.total_locked_earnings) {
        totalEarnings += data.performance.locked_earnings.total_locked_earnings;
      }
      if (data.performance.pending_earnings.total_pending_earnings) {
        totalEarnings += data.performance.pending_earnings.total_pending_earnings;
      }
      return this.getFormattedCurrencyValue(currency, totalEarnings);
    },
    saveLinkDescription(data, index, value) {
      this.editAffiliateLinkProductDetails(
        index,
        data.publisher_affiliate_link_id,
        data.brand,
        data.product_name,
        value,
        this.collectionId,
      );
    },
    saveBrandName(data, index, value) {
      this.editAffiliateLinkProductDetails(
        index,
        data.publisher_affiliate_link_id,
        value,
        data.product_name,
        data.link_description,
        this.collectionId0,
      );
    },
    saveProductName(data, index, value) {
      this.editAffiliateLinkProductDetails(
        index,
        data.publisher_affiliate_link_id,
        data.brand,
        value,
        data.link_description,
        this.collectionId0,
      );
    },
    getFormattedCurrencyValue(currency, value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(value);
    },
  },
};
</script>
