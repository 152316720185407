import {
  CAPABILITY_FAN,
  CAPABILITY_PUBLICATIONS,
  CAPABILITY_SUB_AFFILIATE_NETWORK,
} from "@/constants/capability-constants";

export const SECTION_HOME = "section_home";
export const SECTION_STORIES = "section_stories";
export const SECTION_PUBLICATIONS = "section_publications";
export const SECTION_MONETIZATION = "section_monetization";
export const SECTION_COMMUNITY = "section_community";
export const SECTION_BILLING = "section_billing";

export const MAIN_NAV_PRIMARY = [
  {
    name: SECTION_HOME,
    label: "Home",
    actionValue: "/",
  },
  {
    name: SECTION_PUBLICATIONS,
    label: "My Publications",
    actionValue: "/publication/org/{org_id}",
    requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  },
  {
    name: SECTION_MONETIZATION,
    label: "Monetization",
    actionValue: "/monetization/org/{org_id}",
    requiredCapabilities: [CAPABILITY_SUB_AFFILIATE_NETWORK],
  },
  {
    name: SECTION_COMMUNITY,
    label: "Community",
    actionValue: "/community",
    requiredCapabilities: [CAPABILITY_FAN],
  },
  {
    name: SECTION_STORIES,
    label: "My Stories",
    actionValue: "/stories",
  },
];

// //////////////////////
// Main Nav Secondary //
// //////////////////////

export const SECTION_DIGITAL_WALLET = "section_digital_wallet";
export const SECTION_SETTINGS = "section_settings";

export const PAGE_ORG_SETTINGS = "page_org_settings";
export const PAGE_USER_SETTINGS = "page_user_settings";
export const PAGE_CONTACT_US = "page_contact_us";
export const PAGE_HELP = "page_help";
export const PAGE_LOGOUT = "page_logout";
export const PAGE_HOME = "page_home";
export const PAGE_STORIES = "page_stories";
export const PAGE_ORGANIZATION = "page_organization";
export const PAGE_INFLUENCER_PLATFORMS = "page_influencer_platforms";
export const PAGE_USER_ACCOUNT = "page_user_account";
export const PAGE_SOCIAL_NETWORKS = "page_social_networks";
export const PAGE_ORG_PROFILE_PAGE = "page_org_profile";
export const PAGE_BILLING_AND_SUBSCRIPTIONS = "page_billing_and_subscriptions";
export const PAGE_DIRECTORY = "page_directory";
export const PAGE_MARKETPLACE = "page_marketplace";
export const PAGE_COMMUNITY_PUBLICATIONS = "page_community_publications";
export const PAGE_INVITES = "page_invites";

export const PAGE_BEAUTY = "page_beauty";
export const PAGE_DIY = "page_diy";
export const PAGE_CREATE_POST = "page_create_post";
export const PAGE_FASHION = "page_create_post";
export const PAGE_TRAVEL = "page_travel";
export const PAGE_FOOD = "page_food";

export const PAGE_THEMES = "page_themes";
export const PAGE_THEME_PALETTES = "page_theme_palettes";
export const PAGE_PUBLICATION_DEPLOYMENT_HISTORY =
  "page_publication_deployment_history";

export const MAIN_NAV_SECONDARY = [
  {
    name: PAGE_ORG_SETTINGS,
    label: "Company Settings",
    actionValue: "/settings/org/{org_id}",
  },
  {
    name: PAGE_USER_SETTINGS,
    label: "My User Account",
    actionValue: "/settings/user/{user_id}",
  },
  {
    name: PAGE_ORG_PROFILE_PAGE,
    label: "My Profile",
    actionValue: "/community/org/{org_id}/profile",
  },
  // TODO this is subject to change - hiding for dark deploy, unhide when you want to use it
  {
    name: PAGE_BILLING_AND_SUBSCRIPTIONS,
    label: "Billing & Subscriptions",
    actionValue: "/billing/org/{org_id}/active",
  },
  {
    name: PAGE_CONTACT_US,
    label: "Contact Us",
    actionValue: "https://bloglovin.freshdesk.com/support/tickets/new",
  },
  {
    name: PAGE_HELP,
    label: "Help",
    actionValue: "https://help.bloglovin.com",
  },
  {
    name: PAGE_LOGOUT,
    label: "Logout",
    actionValue: "/logout",
  },
];

// /////////////////////////
// Monetization Side Nav //
// /////////////////////////

export const PAGE_AFFILIATE = "page_affiliate";
export const PAGE_LINK_GENERATOR = "page_link_generator";
export const PAGE_INSPIRATION_NETWORK = "page_inspiration_network";
export const PAGE_DIRECT_NETWORK = "page_direct_network";
export const PAGE_LINKS_REPORT = "page_links_report";
export const PAGE_COLLECTIONS_REPORT = "page_collections_report";
export const PAGE_LINKS_COLLECTIONS = "page_links_collections";
export const PAGE_ACTIONS_REPORT = "page_actions_report";
export const PAGE_JAVASCRIPT_TAG = "page_javascript_tag";
export const PAGE_BROWSER_PLUGIN = "page_browser_plugins";
export const PAGE_SPONSORED_CONTENT = "page_sponsored_content";
export const PAGE_SUBSCRIPTIONS = "page_subscriptions";
export const PAGE_DIGITAL_PRODUCTS = "page_digital_products";
export const PAGE_DISPLAY_ADS = "page_display_ads";

export const SIDE_NAV_MONETIZATION = [
  {
    name: PAGE_AFFILIATE,
    label: "Affiliate",
    actionValue: "/monetization/org/{org_id}",
    requiredCapabilities: [CAPABILITY_SUB_AFFILIATE_NETWORK],
    subItems: [
      {
        name: PAGE_LINK_GENERATOR,
        label: "Link Generator",
        actionValue: "/monetization/org/{org_id}/link-generator",
      },
      {
        name: PAGE_INSPIRATION_NETWORK,
        label: "Inspiration Network",
        actionValue: "/monetization/org/{org_id}/inspiration-network",
      },
      {
        name: PAGE_DIRECT_NETWORK,
        label: "Direct Network",
        actionValue: "/monetization/org/{org_id}/direct-network",
      },
      {
        name: PAGE_LINKS_REPORT,
        label: "My Links",
        actionValue: "/monetization/org/{org_id}/links",
        subItems: [
          {
            name: PAGE_LINKS_COLLECTIONS,
            label: "Collections",
            actionValue: "/monetization/org/{org_id}/links/collections",
          },
        ],
      },
      {
        name: PAGE_ACTIONS_REPORT,
        label: "Affiliate Sales",
        actionValue: "/monetization/org/{org_id}/actions",
      },
      {
        name: PAGE_JAVASCRIPT_TAG,
        label: "Javascript Tag",
        actionValue: "/monetization/org/{org_id}/js",
      },
      // {
      //   name: PAGE_BROWSER_PLUGIN,
      //   label: "Browser Plugins",
      //   actionValue: "/monetization/browser-plugins",
      // },
    ],
  },
  // {
  //   name: PAGE_SPONSORED_CONTENT,
  //   label: "Sponsored Content",
  //   actionValue: "/monetization/org/{org_id}/sponsored-content",
  //   requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  // },
  // {
  //   name: PAGE_SUBSCRIPTIONS,
  //   label: "Subscription",
  //   actionValue: "/monetization/org/{org_id}/subscriptions",
  //   requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  // },
  // {
  //   name: PAGE_DIGITAL_PRODUCTS,
  //   label: "Digital Products",
  //   actionValue: "/monetization/org/{org_id}/digital-products",
  //   requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  // },
  // {
  //   name: PAGE_DISPLAY_ADS,
  //   label: "Display Ads",
  //   actionValue: "/monetization/org/{org_id}/display-ads",
  //   requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  // },
];

// //////////////////////////////////
// Publication Section Page Names //
// //////////////////////////////////
export const PAGE_MY_PUBLICATIONS = "page_my_publications";
export const PAGE_CREATE_PUBLICATION = "page_create_publication";
export const PAGE_COLLABORATING_PUBLICATIONS =
  "page_collaborating_publications";
export const PAGE_CREATE_DIGITAL_PRODUCT = "page_create_digital_product";
export const PAGE_PUBLICATION_DASHBOARD = "page_publication_dashboard";
export const PAGE_PUBLICATION_BRANDING = "page_publication_branding";
export const PAGE_PUBLICATION_DEPLOYMENT = "page_publication_deployment";
export const PAGE_PUBLICATION_DEPLOYMENT_VERSION_HISTORY =
  "page_publication_deployment_version_history";
export const PAGE_PUBLICATION_FEATURES = "page_publication_features";
export const PAGE_PUBLICATION_PRO_FEATURES = "page_publication_pro_features";
export const PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN =
  "page_publication_signup_and_login";
export const PAGE_PUBLICATION_FEATURE_SUBSCRIPTIONS =
  "page_publication_subscriptions";
export const PAGE_PUBLICATION_FEATURE_DIGITAL_PRODUCTS =
  "page_publication_digital_products";
export const PAGE_PUBLICATION_FEATURE_SOCIAL_MEDIA =
  "page_publication_social_media";
export const PAGE_PUBLICATION_FEATURE_POST_CREATION =
  "page_publication_post_creation";
export const PAGE_PUBLICATION_COLLABORATORS = "page_publication_collaborators";
export const PAGE_PUBLICATION_FEATURE_SHOP = "page_publication_shop";
export const PAGE_PUBLICATION_MODERATION = "page_publication_moderation";
export const PAGE_PUBLICATION_CHAT_ROOM = "page_publication_chat_room";
export const PAGE_PUBLICATION_COMMENTS = "page_publication_comments";
export const PAGE_PUBLICATION_NAVIGATION_CONFIG =
  "page_publication_navigation_config";
export const PAGE_PUBLICATION_POSTS = "page_publication_posts";
export const PAGE_PUBLICATION_TEMPLATES = "page_publication_templates";
export const PAGE_PUBLICATION_DOMAIN_CONFIGURATION =
  "page_publication_domain_configuration";
export const PAGE_MY_ACTIVE_PUBLICATIONS = "page_my_active_publications";
export const PAGE_MY_DRAFT_PUBLICATIONS = "page_my_draft_publications";
export const PAGE_MY_INACTIVE_PUBLICATIONS = "page_my_inactive_publications";
export const PAGE_PUBLICATION_SETTINGS = "page_publication_settings";
export const PAGE_PUBLICATION_DIGITAL_PRODUCTS =
  "page_publication_digital_products";
export const PAGE_STORY_EDITOR = "page_story_editor";
export const PAGE_PUBLICATION_FEED_GROUP_LIST = "feed_group_list";
export const PAGE_PUBLICATION_FEED_GROUP = "feed_group";
export const PAGE_PUBLICATION_COLLABORATION_AD_DETAILS = "page_publication_collaboration_ad_details";

// //////////////////////////////////
// Community Section Page Names //
// //////////////////////////////////

export const PAGE_COMMUNITY_COMMUNITY = "page_community";
export const PAGE_COMMUNITY_TOPIC = "page_community_topic";
export const PAGE_COMMUNITY_SETTINGS = "page_community_settings";
export const PAGE_COMMUNITY_PUBLICATION_COLLABORATION_ADS = "page_community_publication_collaboration_ads";

export const PAGE_PUBLICATION_USER_MANAGEMENT = "page_user_management";
export const PAGE_PUBLICATION_USER_ENGAGEMENT = "page_user_engagement";
export const PAGE_PUBLICATION_USER_POSTING = "page_user_posting";
export const PAGE_PUBLICATION_USERS = "page_my_users";
export const PAGE_COMMUNITY_ADVERTISERS = "page_community_advertisers";
export const PAGE_COMMUNITY_CREATORS = "page_community_creators";

export const PAGE_DESIGN_MARKETPLACE = "page_design_marketplace";
export const PAGE_DESIGN_MARKETPLACE_PUBLICATION_TEMPLATES = "page_design_marketplace_publication_templates";
export const PAGE_DESIGN_MARKETPLACE_THEMES = "page_design_marketplace_themes";
export const PAGE_DESIGN_MARKETPLACE_COMPONENTS = "page_design_marketplace_components";

export const SIDE_NAV_COMMUNITY = [
  // {
  //   name: PAGE_COMMUNITY_COMMUNITY,
  //   label: "Home",
  //   actionValue: "/community",
  // },
  {
    name: PAGE_DIRECTORY,
    label: "Directory",
    actionValue: "/community/directory",
    subItems: [
      {
        name: PAGE_COMMUNITY_ADVERTISERS,
        label: "Advertisers",
        actionValue: "/community/directory/advertisers",
      },
      {
        name: PAGE_COMMUNITY_CREATORS,
        label: "Creators",
        actionValue: "/community/directory/creators",
      },
    ],
  },
  {
    name: PAGE_COMMUNITY_PUBLICATIONS,
    label: "Publications",
    actionValue: "/community/publications",
  },
  {
    name: PAGE_COMMUNITY_PUBLICATION_COLLABORATION_ADS,
    label: "Collaboration Ads",
    actionValue: "/community/collaboration-ads",
  },
  {
    name: PAGE_MARKETPLACE,
    label: "Syndication Marketplace",
    actionValue: "/community/syndication-marketplace",
  },
  {
    name: PAGE_INVITES,
    label: "Refer a friend",
    actionValue: "/community/invites",
  },
  {
    name: PAGE_DESIGN_MARKETPLACE,
    label: "Design Marketplace",
    actionValue: "/community/design-marketplace",
    subItems: [
      {
        name: PAGE_DESIGN_MARKETPLACE_PUBLICATION_TEMPLATES,
        label: "Publication Templates",
        actionValue: "/community/design-marketplace/publication-templates",
      },
      {
        name: PAGE_DESIGN_MARKETPLACE_THEMES,
        label: "Themes",
        actionValue: "/community/design-marketplace/themes",
      },
      {
        name: PAGE_DESIGN_MARKETPLACE_COMPONENTS,
        label: "Components",
        actionValue: "/community/design-marketplace/components",
      },
    ],
  },
];

// /////////////////////////////////////
// Digital Wallet Section Page Names //
// /////////////////////////////////////

export const PAGE_DIGITAL_WALLET_DASHBOARD = "page_digital_wallet_dashboard";
export const PAGE_WITHDRAWAL_HISTORY = "page_digital_wallet_withdrawals";
export const PAGE_PENDING_EARNINGS = "page_digital_wallet_pending_earnings";
export const PAGE_INVOICES = "page_digital_wallet_invoices";
export const PAGE_WALLET_SETTINGS = "page_digital_wallet_settings";

export const SIDE_NAV_DIGITAL_WALLET = [
  {
    name: PAGE_DIGITAL_WALLET_DASHBOARD,
    label: "Digital Wallet",
    actionValue: "/digital-wallet/org/{org_id}",
  },
  {
    name: PAGE_WITHDRAWAL_HISTORY,
    label: "Withdrawals",
    actionValue: "/digital-wallet/org/{org_id}/withdrawals",
  },
  {
    name: PAGE_PENDING_EARNINGS,
    label: "Pending Earnings",
    actionValue: "/digital-wallet/org/{org_id}/pending-earnings",
  },
  {
    name: PAGE_INVOICES,
    label: "Invoices",
    actionValue: "/digital-wallet/org/{org_id}/invoices",
  },
  {
    name: PAGE_WALLET_SETTINGS,
    label: "Wallet Settings",
    actionValue: "/digital-wallet/org/{org_id}/settings",
  },
];

// //////////////////////////////
// Settings Section Page Names //
// //////////////////////////////

export const PAGE_SOCIAL_NETWORK_SETTINGS = "page_social_network_settings";
export const PAGE_COLLABORATOR_INVITATIONS_SETTINGS =
  "page_collaborator_invitations_settings";
export const PAGE_AFFILIATE_PLATFORM_SETTINGS =
  "page_affiliate_platform_settings";
export const PAGE_INFLUENCER_PLATFORM_SETTINGS =
  "page_influencer_platform_settings";
export const PAGE_USER_ACCOUNT_SETTINGS = "page_user_settings";
export const PAGE_ORGANIZATION_SETTINGS = "page_org_settings";

export const SIDE_NAV_SETTINGS = [
  {
    name: PAGE_ORG_SETTINGS,
    label: "Company Settings",
    actionValue: "/settings/org/{org_id}",
  },
  {
    name: PAGE_USER_SETTINGS,
    label: "User Settings",
    actionValue: "/settings/user/{user_id}",
  },
  {
    name: PAGE_SOCIAL_NETWORK_SETTINGS,
    label: "Social Networks",
    actionValue: "/settings/org/{org_id}/social-networks",
  },
  {
    name: PAGE_AFFILIATE_PLATFORM_SETTINGS,
    label: "Affiliate Platforms",
    actionValue: "/settings/org/{org_id}/affiliate-platforms",
    requiredCapabilities: [CAPABILITY_SUB_AFFILIATE_NETWORK],
  },
  // {
  //   name: PAGE_COLLABORATOR_INVITATIONS_SETTINGS,
  //   label: "Collaboration",
  //   actionValue: "/settings/org/{org_id}/invitations",
  //   requiredCapabilities: [CAPABILITY_PUBLICATIONS],
  // },
  // {
  //   name: PAGE_INFLUENCER_PLATFORM_SETTINGS,
  //   label: "Influencer Platforms",
  //   actionValue: "/settings/org/{org_id}/influencer-platforms",
  // },
];

// //////////////////////////////
// Billing Section Page Names //
// //////////////////////////////

export const PAGE_ACTIVE_SUBSCRIPTIONS = "page_active_subscriptions";
export const PAGE_CANCELLED_SUBSCRIPTIONS = "page_cancelled_subscriptions";
export const PAGE_BILLING_HISTORY = "page_billing_history";
export const PAGE_PURCHASED_DIGITAL_PRODUCTS = "page_purchased_digital_products";

export const SIDE_NAV_BILLING = [
  {
    name: PAGE_ACTIVE_SUBSCRIPTIONS,
    label: "Active Subscriptions",
    actionValue: "/billing/org/{org_id}/active",
  },
  {
    name: PAGE_BILLING_HISTORY,
    label: "Billing History",
    actionValue: "/billing/org/{org_id}/history",
  },
  {
    name: PAGE_CANCELLED_SUBSCRIPTIONS,
    label: "Cancelled Subscriptions",
    actionValue: "/billing/org/{org_id}/cancelled",
  },
  {
    name: PAGE_PURCHASED_DIGITAL_PRODUCTS,
    label: "Purchased Digital Products",
    actionValue: "/billing/org/{org_id}/digital-products",
  },
];
