<template>
   <TestChild />
</template>

<script>
  //<TestChild />
import TestChild from "@/components/splash/TestChild";

export default {
  name: "TestParent",
  components: { TestChild },
  mounted() {
console.log(this.$el.childNodes);
    debugger;
    this.$el.parentNode.className += " nick-" + this.$.type.__scopeId;
  },
};
</script>

<style scoped lang="scss">
:deep(.test-child) {
  color: blue;
}
</style>
